import s from "./workout-creation-page.module.scss";

export const EDLUploader = ({ onPartsGenerated }) => {
 const handleEDLUpload = (event) => {
   const file = event.target.files[0];
   if (!file) return;

   const reader = new FileReader();
   reader.onload = (e) => {
     const edlContent = e.target.result;
     const parts = convertEDLToParts(edlContent);
     console.log("EDL processed parts:", parts);
     onPartsGenerated(parts);
   };
   reader.readAsText(file);
 };

 return (
   <div className={s.edl_uploader}>
     <label>
       <input
         type="file"
         accept=".edl"
         onChange={handleEDLUpload}
         style={{ display: 'none' }}
       />
       <span className={s.edl_button}>Import EDL</span>
     </label>
   </div>
 );
};

const convertEDLToParts = (edlContent) => {
 const lines = edlContent.split('\n');
 
 // Check which type of EDL we're dealing with
 const isMediumFirst = checkIfMediumFirst(lines);
 const isHardFirst = checkIfHardFirst(lines);
 const hasLowParts = checkForLowParts(lines);
 
 if (isMediumFirst) {
   return convertEDLToPartsForMediumFirst(lines);
 } else if (isHardFirst && !hasLowParts) {
   return convertEDLToPartsForHardFirst(lines);
 } else {
   return convertEDLToPartsOriginal(lines);
 }
};

const checkIfMediumFirst = (lines) => {
 let foundWarmup = false;
 
 for (let i = 0; i < lines.length; i++) {
   const line = lines[i];
   const nextLine = lines[i + 1] || '';
   const clipName = nextLine.includes('FROM CLIP NAME:') ? 
     nextLine.split('FROM CLIP NAME:')[1].trim().toLowerCase() : '';

   if (clipName.includes('warmup')) {
     foundWarmup = true;
   } else if (foundWarmup && clipName) {
     return clipName.includes('med');
   }
 }
 return false;
};

const checkIfHardFirst = (lines) => {
  let foundWarmup = false;
  
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const nextLine = lines[i + 1] || '';
    const clipName = nextLine.includes('FROM CLIP NAME:') ? 
      nextLine.split('FROM CLIP NAME:')[1].trim().toLowerCase() : '';

    if (clipName.includes('warmup')) {
      foundWarmup = true;
    } else if (foundWarmup && clipName) {
      return clipName.includes('hard');
    }
  }
  return false;
};

const convertEDLToPartsForMediumFirst = (lines) => {
 let parts = [];
 let currentPart = null;

 for (let i = 0; i < lines.length; i++) {
   const line = lines[i];
   if (!line.startsWith('0')) continue;

   const nextLine = lines[i + 1] || '';
   const clipName = nextLine.includes('FROM CLIP NAME:') ? 
     nextLine.split('FROM CLIP NAME:')[1].trim().toLowerCase() : '';

   const timecodes = line.match(/\d{2}:\d{2}:\d{2}:\d{2}/g);
   if (!timecodes || timecodes.length < 4) continue;

   const timeStart = convertToMMSS(timecodes[2]);
   const timeEnd = subtractOneSecond(convertToMMSS(timecodes[3]));

   if (clipName.includes('warmup')) {
     // Handle warmup the same as before
     const warmupPart = createPart('Warmup', timeStart, timeEnd, 90);
     warmupPart.nodes[0] = { ...warmupPart.nodes[0], timeStart: "00:01", timeEnd };
     warmupPart.nodes[1] = { ...warmupPart.nodes[1], timeStart: "00:00", timeEnd };
     warmupPart.nodes[2] = { ...warmupPart.nodes[2], timeStart: "00:01", timeEnd };
     parts.push(warmupPart);
   } else if (clipName.includes('cooldown')) {
     // Handle cooldown the same as before
     const cooldownPart = createPart('Cooldown', timeStart, convertToMMSS(timecodes[3]), 90);
     cooldownPart.nodes.forEach(node => {
       node.timeStart = timeStart;
       node.timeEnd = convertToMMSS(timecodes[3]);
     });
     parts.push(cooldownPart);
   } else if (clipName.includes('med')) {
     const partNumber = clipName.match(/\d+/) ? clipName.match(/\d+/)[0] : parts.length + 1;
     currentPart = createPart(`Part ${partNumber}`, timeStart, timeEnd, 115);
     // Use medium times for HIGH node
     currentPart.nodes[2] = { ...currentPart.nodes[2], timeStart, timeEnd };
     parts.push(currentPart);
   } else if (clipName.includes('low') && currentPart) {
     // Use low times for MEDIUM node of current part
     currentPart.nodes[1] = { ...currentPart.nodes[1], timeStart, timeEnd };
     // Also use same times for LOW node
     currentPart.nodes[0] = { ...currentPart.nodes[0], timeStart, timeEnd };
   }
 }

 return parts;
};

const convertEDLToPartsForHardFirst = (lines) => {
  let parts = [];
  let currentPart = null;

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (!line.startsWith('0')) continue;

    const nextLine = lines[i + 1] || '';
    const clipName = nextLine.includes('FROM CLIP NAME:') ? 
      nextLine.split('FROM CLIP NAME:')[1].trim().toLowerCase() : '';

    const timecodes = line.match(/\d{2}:\d{2}:\d{2}:\d{2}/g);
    if (!timecodes || timecodes.length < 4) continue;

    const timeStart = convertToMMSS(timecodes[2]);
    const timeEnd = subtractOneSecond(convertToMMSS(timecodes[3]));

    if (clipName.includes('warmup')) {
      // Handle warmup the same as before
      const warmupPart = createPart('Warmup', timeStart, timeEnd, 90);
      warmupPart.nodes[0] = { ...warmupPart.nodes[0], timeStart: "00:01", timeEnd };
      warmupPart.nodes[1] = { ...warmupPart.nodes[1], timeStart: "00:00", timeEnd };
      warmupPart.nodes[2] = { ...warmupPart.nodes[2], timeStart: "00:01", timeEnd };
      parts.push(warmupPart);
    } else if (clipName.includes('cooldown')) {
      // Handle cooldown the same as before
      const cooldownPart = createPart('Cooldown', timeStart, convertToMMSS(timecodes[3]), 90);
      cooldownPart.nodes.forEach(node => {
        node.timeStart = timeStart;
        node.timeEnd = convertToMMSS(timecodes[3]);
      });
      parts.push(cooldownPart);
    } else if (clipName.includes('hard')) {
      const partNumber = clipName.match(/\d+/) ? clipName.match(/\d+/)[0] : parts.length + 1;
      currentPart = createPart(`Part ${partNumber}`, timeStart, timeEnd, 115);
      // Use hard times for both HIGH and MEDIUM nodes
      currentPart.nodes[2] = { ...currentPart.nodes[2], timeStart, timeEnd };
      currentPart.nodes[1] = { ...currentPart.nodes[1], timeStart, timeEnd };
    } else if (clipName.includes('med') && currentPart) {
      // Use medium times for LOW node
      currentPart.nodes[0] = { ...currentPart.nodes[0], timeStart, timeEnd };
      parts.push(currentPart);
    }
  }

  return parts;
};

// Rename existing conversion function
const convertEDLToPartsOriginal = (lines) => {
 let parts = [];
 let currentPart = null;

 for (let i = 0; i < lines.length; i++) {
   const line = lines[i];
   if (!line.startsWith('0')) continue;

   const nextLine = lines[i + 1] || '';
   const clipName = nextLine.includes('FROM CLIP NAME:') ? 
     nextLine.split('FROM CLIP NAME:')[1].trim().toLowerCase() : '';

   const timecodes = line.match(/\d{2}:\d{2}:\d{2}:\d{2}/g);
   if (!timecodes || timecodes.length < 4) continue;

   const timeStart = convertToMMSS(timecodes[2]);
   const timeEnd = subtractOneSecond(convertToMMSS(timecodes[3]));

   if (clipName.includes('warmup')) {
     const warmupPart = createPart('Warmup', timeStart, timeEnd, 90);
     // Set LOW node
     warmupPart.nodes[0] = { ...warmupPart.nodes[0], timeStart: "00:01", timeEnd };
     // Set MEDIUM node
     warmupPart.nodes[1] = { ...warmupPart.nodes[1], timeStart: "00:00", timeEnd };
     // Set HIGH node
     warmupPart.nodes[2] = { ...warmupPart.nodes[2], timeStart: "00:01", timeEnd };
     parts.push(warmupPart);
   } else if (clipName.includes('cooldown')) {
     const cooldownPart = createPart('Cooldown', timeStart, convertToMMSS(timecodes[3]), 90);
     cooldownPart.nodes.forEach(node => {
       node.timeStart = timeStart;
       node.timeEnd = convertToMMSS(timecodes[3]);
     });
     parts.push(cooldownPart);
   } else if (clipName.includes('hard')) {
     const partNumber = clipName.match(/\d+/)[0];
     currentPart = createPart(`Part ${partNumber}`, timeStart, timeEnd, 115);
     currentPart.nodes[2] = { ...currentPart.nodes[2], timeStart, timeEnd };
     parts.push(currentPart);
   } else if (clipName.includes('med') && currentPart) {
     currentPart.nodes[1] = { ...currentPart.nodes[1], timeStart, timeEnd };
   } else if (clipName.includes('low') && currentPart) {
     currentPart.nodes[0] = { ...currentPart.nodes[0], timeStart, timeEnd };
   }
 }

 console.log("Converted parts:", parts);
 return parts;
};

const convertToMMSS = (timecode) => {
 const [hours, minutes, seconds] = timecode.split(':');
 const totalMinutes = (parseInt(hours) * 60) + parseInt(minutes);
 return `${totalMinutes.toString().padStart(2, '0')}:${seconds}`;
};

const subtractOneSecond = (time) => {
 const [minutes, seconds] = time.split(':').map(Number);
 let newSeconds = seconds - 1;
 let newMinutes = minutes;
 
 if (newSeconds < 0) {
   newSeconds = 59;
   newMinutes--;
 }
 
 return `${newMinutes.toString().padStart(2, '0')}:${newSeconds.toString().padStart(2, '0')}`;
};

const createPart = (name, timeStart, timeEnd, targetHR) => ({
  partId: Date.now() + Math.random(),
  name,
  nodes: [
    { nodeId: Date.now() + Math.random(), timeStart: "00:00", timeEnd: "00:00", difficulty: "LOW" },
    { nodeId: Date.now() + Math.random(), timeStart: "00:00", timeEnd: "00:00", difficulty: "MEDIUM" },
    { nodeId: Date.now() + Math.random(), timeStart: "00:00", timeEnd: "00:00", difficulty: "HIGH" }
  ],
  tag: "Legs",  // Changed to match partTagHandler's format
  targetHR
});

const checkForLowParts = (lines) => {
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const nextLine = lines[i + 1] || '';
    const clipName = nextLine.includes('FROM CLIP NAME:') ? 
      nextLine.split('FROM CLIP NAME:')[1].trim().toLowerCase() : '';

    if (clipName.includes('low')) {
      return true;
    }
  }
  return false;
};