import s from "./workout-creation-page.module.scss";
import { useEffect, useState } from "react";
import { Input } from "../../../components/input/Input";
import { Multiselect, Select } from "../../../components/select-menu";
import sportEquipmentIcon from "../../../assets/image/sport-equipment-icon.png";
import coachIcon from "../../../assets/image/coach.png";
import bodyPartIcon from "../../../assets/image/body-part.png";
import workoutStyleIcon from "../../../assets/image/workout-style-icon.png";
import trueFalseIcon from "../../../assets/image/true_false.png";
import { bodyParts, workoutStyles } from "../../../assets/constants";
import { useDispatch, useSelector } from "react-redux";
import { instructorsSelector } from "../../../store/selectors/instructorsSelector";
import { getInstructorsList } from "../../../store/operations/instructors-operations";
import { getAllEquipmentsList } from "../../../store/operations/equipments-operations";
import { equipmentsSelector } from "../../../store/selectors/equipments-selector";
import { Button } from "../../../components/button/Button";
import WorkoutsAPI from "../../../api/WorkoutsAPI";
import { useAlert } from "../../../hooks/useAlert";
import { Vimeo } from "vimeo";
import videoFileIcon from "../../../assets/image/video-file.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NO_THUMBNAIL_IMAGE from '../../../assets/image/no-thumbnail-image.jpg';
import { EDLUploader } from './EDLHandler';

const { REACT_APP_VIMEO_CLIENT_ID, REACT_APP_VIMEO_CLIENT_SECRET, REACT_APP_VIMEO_ACCESS_TOKEN } = process.env;

const prioritizeWhenWatchConnectedOptions = [{_id: 'true', title: 'True'}, {_id: 'false', title: 'False'}];
const isFitnessTestVideo = [{_id: 'true', title: 'Test'}, {_id: 'false', title: 'Production'}];
const tagList = [
  { title: 'Arms', _id: 1 },
  { title: 'Legs', _id: 2 },
  { title: 'Chest', _id: 3 },
  { title: 'Core', _id: 4 },
  { title: 'Cardio', _id: 5 }
];

const _baseWorkoutData = {
  title: "",
  difficulty: 1,
  bodyPart: [],
  style: "",
  priority: 10,
  instructor: "",
  calory: 200,
  hr: 60,
  isFitnessTestVideo: false,
  equipments: [],
};

const _basePartData = {
  name: "Part name",
  nodes: [
    {
      nodeId: Math.ceil(Math.random() * Date.now()),
      timeStart: "00:00",
      timeEnd: "00:00",
      difficulty: "LOW",
    },
    {
      nodeId: Math.ceil(Math.random() * Date.now()),
      timeStart: "00:00",
      timeEnd: "00:00",
      difficulty: "MEDIUM",
    },
    {
      nodeId: Math.ceil(Math.random() * Date.now()),
      timeStart: "00:00",
      timeEnd: "00:00",
      difficulty: "HIGH",
    },
  ],
  tag: '',
  targetHR: 90,
};

const _basePartsArrayData = [{ ..._basePartData, partId: Date.now() }];

const VimeoAPI = {
  getStatus: async (videoId) => {
    const response = await axios.get(
      `https://api.vimeo.com/videos/${videoId}?fields=transcode.status,status`,
      {
        headers: {
          Authorization: `bearer ${REACT_APP_VIMEO_ACCESS_TOKEN}`,
          Accept: "application/vnd.vimeo.*+json;version=3.4",
        },
      }
    );

    return response;
  },
};

function vimeoVideoURItoVideoID(uri) {
  const [videoId] = uri.match(/[0-9]+/);
  return videoId;
}

function pollTranscodingStatus(uri, onSuccess, onError) {
  return async () => {
    const videoId = vimeoVideoURItoVideoID(uri);
    const response = await VimeoAPI.getStatus(videoId);
    console.log('response', response);
    const { transcode, status } = response.data;
    const transcodeStatus = transcode?.status;

    if (status === 'available' || transcodeStatus === 'complete') {
      onSuccess();
    } else if (status === 'transcode_starting' || status === 'transcoding' || transcodeStatus === 'in_progress') {
      setTimeout(pollTranscodingStatus(uri, onSuccess, onError), 5000);
    } else {
      onError('Transcoding failed');
    }
  };
}

export const CreateWorkoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [successAlert, errorAlert] = useAlert();
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [baseWorkoutData, setBaseWorkoutData] = useState(_baseWorkoutData);
  const [isCreatingInProgress, setIsCreatingInProgress] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [defaultThumbnail, setDefaultThumbnail] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState("0");
  const [innerWorkoutId, setInnerWorkoutId] = useState(null);
  const [vimeoVideoUri, setVimeoVideoUri] = useState(null);
  const [parts, setParts] = useState(_basePartsArrayData);
  const { instructorsList } = useSelector(instructorsSelector);
  const { equipmentsList } = useSelector(equipmentsSelector);

  useEffect(() => {
    dispatch(getInstructorsList(0, 99999));
  }, []);

  useEffect(() => {
    dispatch(getAllEquipmentsList());
  }, []);

  const createVideoData = (file) => {
    return new Promise((resolve) => {
      const _video = document.createElement("video");
      const _canvas = document.createElement("canvas");

      _video.autoplay = true;
      _video.muted = true;
      _video.src = URL.createObjectURL(file);

      _video.onloadeddata = () => {
        let ctx = _canvas.getContext("2d");

        _canvas.width = _video.videoWidth;
        _canvas.height = _video.videoHeight;

        ctx.drawImage(_video, 0, 0);

        _video.pause();

        return resolve({
          preview: _canvas.toDataURL("image/png"),
          video: file,
        });
      };
    });
  };

  const videoInputHandler = async (e) => {
    const noThumbnailImageBlob = await fetch(NO_THUMBNAIL_IMAGE).then(r => r.blob());
    setDefaultThumbnail(noThumbnailImageBlob);
    createVideoData(e.target.files[0])
      .then(({ video, preview }) => {
        setVideo(video);
        setVideoPreview(preview);
      })
      .catch((err) => console.log(err));
  };

  const thumbnailHandler = (e) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      setThumbnailImage(reader.result);
    };
    reader.readAsDataURL(e?.target?.files[0]);
    setVideoThumbnail(e?.target?.files[0]);
  };

  const inputHandler = (e) => {
    settingsHandler(e.target.name, e.target.value);
  };

  const settingsHandler = (name, value) => {
    let _value = value;

    if (name === "style") _value = value?.toUpperCase();
    if (name === 'prioritizeWhenWatchConnected') {
      _value = value === 'True';
    }
    if (name === 'isFitnessTestVideo') {
      _value = value === 'Test';
    }

    setBaseWorkoutData({
      ...baseWorkoutData,
      [name]: _value,
    });
  };

  const partsChangeHandler = (type, value, partId, nodeId) => {
    let partIndex = parts.findIndex((part) => +part.partId === partId);
    let nodeIndex = parts[partIndex].nodes.findIndex(
      (node) => +node.nodeId === +nodeId
    );

    let _newPartsArray = [...parts];
    let _newNodesArray = [...parts[partIndex].nodes];

    switch (type) {
      case "timeStart":
      case "timeEnd":
        let timeRegExp = new RegExp(/^([0-9]{0,3})?:([0-9]{0,2})?$/);
        if (value.match(timeRegExp)) {
          _newNodesArray.splice(nodeIndex, 1, {
            ...parts[partIndex].nodes[nodeIndex],
            [type]: value === ":" ? "000:00" : value,
          });
        }
        break;
      default:
        throw new Error("Bad input type");
    }

    _newPartsArray.splice(partIndex, 1, {
      ...parts[partIndex],
      nodes: _newNodesArray,
    });

    setParts(_newPartsArray);
  };

  const targetHRHandler = ({ target: { value, id } }) => {
    const partIndex = parts.findIndex((part) => +part.partId === +id);
    let _newPartsArray = [...parts];
    let hrRegExp = new RegExp(/^([0-9]{0,3})?$/);

    if (value.match(hrRegExp)) {
      _newPartsArray.splice(partIndex, 1, {
        ...parts[partIndex],
        targetHR: value === "" ? "0" : value,
      });
    }

    setParts(_newPartsArray);
  };

  const partNameHandler = ({ target: { value, id } }) => {
    const partIndex = parts.findIndex((part) => +part.partId === +id);
    let _newPartsArray = [...parts];

    _newPartsArray.splice(partIndex, 1, {
      ...parts[partIndex],
      name: value,
    });
    setParts(_newPartsArray);
  };

  const partTagHandler = (name, title, id) => {
  const partIndex = parts.findIndex((part) => +part.partId === +id);
  let _newPartsArray = [...parts];
  _newPartsArray.splice(partIndex, 1, {
    ...parts[partIndex],
    tag: title,
  });
  console.log("After tag selection:", _newPartsArray); // Add this
  setParts(_newPartsArray);
};

  const addPart = () => {
    setParts([...parts, { ..._basePartData, partId: Date.now() }]);
  };

  const removePart = (id) => {
    setParts(parts.filter((p) => +p.partId !== +id));
  };

  const timeToSeconds = (time) => {
    const [minutes, seconds] = time.split(":");
    return +minutes * 60 + +seconds;
  };

  const getPreparedPartsData = () => {
    return parts.map(({ name, nodes, targetHR, tag }) => {
      return {
        name,
        nodes: nodes.map(({ timeStart, timeEnd, difficulty }) => {
          return {
            timeEnd: timeToSeconds(timeEnd),
            timeStart: timeToSeconds(timeStart),
            difficult: difficulty,
          };
        }),
        exercise: tag,
        targetHR: +targetHR,
      };
    });
  };

  const createWorkoutHandler = (event) => {
    event.preventDefault();

    const isPartsValid = checkIsPartsValid();

    if (isPartsValid) {
      setIsCreatingInProgress(true);

      new WorkoutsAPI()
        .createWorkout(baseWorkoutData)
        .then((res) => {
          successAlert("Workout has been created! Please, upload video!");

          setInnerWorkoutId(res.data._id);

          const onProgressUpdate = (bytes_uploaded, bytes_total) => {
            setProgressPercentage(
              ((bytes_uploaded / bytes_total) * 100).toFixed(2)
            );
          };

          const onError = (error) => {
            errorAlert(error);
          };

          const videoParams = {
            name: baseWorkoutData.title,
            description: "",
            embed: {
              color: "#FE6D02",
              buttons: {
                like: false,
                embed: false,
                share: false,
                watchlater: false,
              },
              logos: {
                vimeo: false,
              },
              title: {
                owner: "hide",
                name: "hide",
                portrait: "hide",
              },
            },
            privacy: {
              add: false,
              comments: "nobody",
              download: false,
            },
          };

          function onTranscodeSuccess() {
            setCurrentStep(4);
          }

          function onTranscodeError(error) {
            errorAlert(error);
            setIsCreatingInProgress(false);
            setCurrentStep(1);
          }

          const onUploadFinish = (uri) => {
            setCurrentStep(3);
            setVimeoVideoUri(uri);

            setTimeout(pollTranscodingStatus(uri, onTranscodeSuccess, onTranscodeError), 5000);
          };

          const videoFile = new File([video], baseWorkoutData.title);
          const vimeoClient = new Vimeo(
            REACT_APP_VIMEO_CLIENT_ID,
            REACT_APP_VIMEO_CLIENT_SECRET,
            REACT_APP_VIMEO_ACCESS_TOKEN
          );

          setCurrentStep(2);

          vimeoClient.upload(
            videoFile,
            videoParams,
            onUploadFinish,
            onProgressUpdate,
            onError
          );
        })
        .catch((error) => {
          errorAlert(error);
          setIsCreatingInProgress(false);
          setCurrentStep(1);
        });
    }
  };

  const checkIsPartsValid = () => {
    let isError = false;

    const invalidParts = parts.filter(
      ({ nodes }) =>
        nodes.filter(({ timeStart, timeEnd }) => {
          return (
            timeToSeconds(timeStart) <= 0 ||
            timeToSeconds(timeEnd) - timeToSeconds(timeStart) <= 0
          );
        }).length
    );

    let invalidNodes = [];

    invalidParts.forEach(({ nodes }) => invalidNodes.push(...nodes));

    invalidNodes = invalidNodes.filter(
      ({ timeStart }) => timeToSeconds(timeStart) <= 0
    );

    if (invalidNodes.length > 1) {
      isError = true;
      errorAlert({ message: "Only one node can start at 00:00" });
    }

    invalidParts.forEach(({ nodes }) => invalidNodes.push(...nodes));

    invalidNodes = invalidNodes.filter(
      ({ timeStart, timeEnd }) =>
        timeToSeconds(timeEnd) - timeToSeconds(timeStart) <= 0);

    if (invalidNodes.length > 1) {
      isError = true;
      errorAlert({ message: "Time end should be bigger then time start" });
    }

    return !isError;
  };

  return (
    <>
      {isCreatingInProgress && (
        <dialog className={s.dialog} open={true}>
          <div className={s.dialog__content}>
            {currentStep === 1 && (
              <>
                <div className={s.dialog__content__header}>
                  <p data-current-step={true}>1</p>
                  <span />
                  <p>2</p>
                  <span />
                  <p>3</p>
                  <span />
                  <p>4</p>
                </div>
                <div className={s.dialog__content__progress}>
                  <p className={s.dialog__content__progress__first}>
                    Creating workout data
                  </p>
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <div className={s.dialog__content__header}>
                  <p>1</p>
                  <span />
                  <p data-current-step={true}>2</p>
                  <span />
                  <p>3</p>
                  <span />
                  <p>4</p>
                </div>
                <div className={s.dialog__content__progress}>
                  <p>Uploading video</p>
                  <div className={s.dialog__content__progress__bar}>
                    <span style={{ width: progressPercentage + "%" }} />
                    <p>{progressPercentage}%</p>
                  </div>
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                <div className={s.dialog__content__header}>
                  <p>1</p>
                  <span />
                  <p>2</p>
                  <span />
                  <p data-current-step={true}>3</p>
                  <span />
                  <p>4</p>
                </div>
                <div className={s.dialog__content__progress}>
                  <p className={s.dialog__content__progress__first}>
                    Preparing parts and transcoding
                  </p>
                </div>
              </>
            )}
            {currentStep === 4 && (
              <>
                <div className={s.dialog__content__header}>
                  <p>1</p>
                  <span />
                  <p>2</p>
                  <span />
                  <p>3</p>
                  <span />
                  <p data-current-step={true}>4</p>
                </div>
                <div className={s.dialog__content__progress}>
                  <Button
                    className={s.dialog__content__progress__publish}
                    onClick={async () => {
                      try {
                        await new WorkoutsAPI().updateWorkoutVideo(
                          {
                            url: `https://vimeo.com/${vimeoVideoURItoVideoID(
                              vimeoVideoUri
                            )}`,
                            parts: getPreparedPartsData(),
                          },
                          innerWorkoutId
                        );

                        if (videoThumbnail !== null) {
                          await new WorkoutsAPI().addCustomThumbnail(
                            {
                              customThumbnail: videoThumbnail,
                            },
                            innerWorkoutId
                          );
                        }

                        successAlert("Workout has been updated!");
                        window.open(
                          `${window.location.origin}/dashboard/workouts/${innerWorkoutId}`,
                          "_blank"
                        );
                        navigate("/dashboard/workouts", { target: "_blank" });
                      } catch (error) {
                        errorAlert(error);
                        setIsCreatingInProgress(false);
                      }
                    }}
                  >
                    Update parts and publish
                  </Button>
                </div>
              </>
            )}
            <small>
              Don't close this window until workout will be created!
            </small>
          </div>
        </dialog>
      )}
      <form onSubmit={createWorkoutHandler} className={s.wrapper}>
        <div className={s.wrapper__video}>
          <label className={s.wrapper__video__input}>
            <input
              name={"video_input"}
              multiple={false}
              onChange={videoInputHandler}
              type="file"
              accept="video/*"
            />

            {!video && <img src={videoFileIcon} alt={"addIcon"} />}
            {videoPreview && (
              <img
                src={videoPreview}
                alt={"preview"}
                className={s.wrapper__video__input__preview}
              />
            )}
          </label>
          <div className={s.wrapper__video__settings}>
            <Input
              required={true}
              onChange={inputHandler}
              className={s.wrapper__video__settings__title}
              name={"title"}
              placeholder={"Workout Title"}
            />
            <Multiselect
              placeholder={"Click to select equipment"}
              onSelect={settingsHandler}
              name={"equipments"}
              required={true}
              icon={sportEquipmentIcon}
              selectClassName={s.wrapper__video__settings__equipment}
              options={equipmentsList.map((e) => ({
                title: e.name,
                _id: e._id,
              }))}
            />
            <Select
              placeholder={"Click to select instructor"}
              icon={coachIcon}
              required={true}
              onSelect={settingsHandler}
              name={"instructor"}
              selectClassName={s.wrapper__video__settings__coach}
              options={instructorsList.map((i) => ({
                title: i.name,
                _id: i._id,
              }))}
            />
            <Multiselect
              placeholder={"Click to select body part"}
              onSelect={settingsHandler}
              name={"bodyPart"}
              required={true}
              icon={bodyPartIcon}
              selectClassName={s.wrapper__video__settings__body_part}
              options={bodyParts.map(({ title }, index) => ({
                title: title,
                _id: index,
              }))}
            />
            <Select
              placeholder={"Click to select workout style"}
              icon={workoutStyleIcon}
              required={true}
              onSelect={settingsHandler}
              name={"style"}
              selectClassName={s.wrapper__video__settings__workout_style}
              options={workoutStyles.map(({ title }, index) => ({
                title,
                _id: index,
              }))}
            />
            <Select
              icon={trueFalseIcon}
              placeholder={"Click to prioritize when watch connected"}
              onSelect={settingsHandler}
              name="prioritizeWhenWatchConnected"
              selectClassName={s.wrapper__video__settings__prioritize_watch_style}
              options={prioritizeWhenWatchConnectedOptions}
            />
            <fieldset className={s.wrapper__video__settings__difficulty}>
              <label htmlFor={"difficulty"}>
                Difficulty ({baseWorkoutData.difficulty})
              </label>
              <Input
                required={true}
                value={baseWorkoutData.difficulty}
                onChange={inputHandler}
                name={"difficulty"}
                type={"range"}
                min={1}
                max={10}
                step={1}
              />
            </fieldset>
            <fieldset className={s.wrapper__video__settings__difficulty}>
              <label htmlFor={"priority"}>
                Priority ({baseWorkoutData.priority})
              </label>
              <Input
                required={true}
                value={baseWorkoutData.priority}
                onChange={inputHandler}
                name={"priority"}
                type={"range"}
                min={1}
                max={10}
                step={1}
              />
            </fieldset>
            <fieldset className={s.wrapper__video__settings__calories}>
              <label htmlFor={"calory"}>
                Calories ({baseWorkoutData.calory})
              </label>
              <Input
                required={true}
                value={baseWorkoutData.calory}
                onChange={inputHandler}
                name={"calory"}
                type={"range"}
                min={200}
                max={1500}
                step={50}
              />
            </fieldset>
            <fieldset className={s.wrapper__video__settings__hr}>
              <label htmlFor={"hr"}>Target HR ({baseWorkoutData.hr})</label>
              <Input
                required={true}
                value={baseWorkoutData.hr}
                onChange={inputHandler}
                name={"hr"}
                type={"range"}
                min={60}
                max={180}
                step={5}
              />
            </fieldset>
            <Select
              icon={trueFalseIcon}
              placeholder={"Select workout mode"}
              onSelect={settingsHandler}
              name="isFitnessTestVideo"
              selectClassName={s.wrapper__video__settings__prioritize_watch_style}
              options={isFitnessTestVideo}
            />
          </div>
        </div>
        <div className={s.hasProfileUploadWrapper}>
          <div className={s.profilePhotoWrapper}>
            <div className={s.hasImage}>
              {thumbnailImage !== null && (
                <img src={thumbnailImage} alt={"Thumbnail_preview"} />
              )}
            </div>
            <div>
              <div className={s.hasUploadButton}>
                <label htmlFor="uploadImage">Add Thumbnail</label>
                <input
                  type="file"
                  id="uploadImage"
                  accept=".png, .jpg"
                  onChange={thumbnailHandler}
                />
                {thumbnailImage && (
                  <p
                    onClick={() => (
                      setThumbnailImage(null), setVideoThumbnail(null)
                    )}
                    style={{ cursor: "pointer" }}
                  >
                    Remove Thumbnail
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {video && (
          <div className={s.wrapper__parts}>
            <EDLUploader onPartsGenerated={setParts} />
            {parts.map(({ nodes, name, partId, targetHR }) => {
              const _fieldHandler = ({ target: { name, value } }) => {
                const [nodeId, type] = name.split("_");
                partsChangeHandler(type, value, partId, nodeId);
              };

              return (
                <div className={s.wrapper__parts__tile} key={partId}>
                  <div className={s.wrapper__parts__tile__header}>
                    <Input
                      id={partId}
                      name={"part_name"}
                      value={name}
                      onChange={partNameHandler}
                    />
                    <Input
                      id={partId}
                      name={"targetHR"}
                      value={targetHR}
                      onChange={targetHRHandler}
                    />
                    <Select
                      placeholder={"Click to select tag"}
                      icon={coachIcon}
                      required={true}
                      onSelect={partTagHandler}
                      id={partId}
                      name={"tag"}
                      selectClassName={s.wrapper__video__settings__coach_2}
                      options={tagList.map((i) => ({
                        title: i.title,
                        _id: i._id,
                      }))}
                    />
                    {parts.length >= 2 && (
                      <Button
                        type={"button"}
                        onClick={() => removePart(partId)}
                        title={"Remove part"}
                      />
                    )}
                    <Button
                      type={"button"}
                      onClick={addPart}
                      title={"Add new Part"}
                    />
                  </div>
                  <table className={s.wrapper__parts__tile__part}>
                    <thead>
                      <tr>
                        <th>Difficulty</th>
                        <th>Time Start</th>
                        <th>Time End</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nodes.map(({ timeStart, timeEnd, difficulty, nodeId }) => (
                        <tr
                          key={nodeId}
                          className={s.wrapper__parts__tile__part__nodes}
                        >
                          <td>{difficulty}</td>
                          <td width={"30%"}>
                            <Input
                              onChange={_fieldHandler}
                              name={`${nodeId}_timeStart`}
                              value={timeStart}
                            />
                          </td>
                          <td width={"30%"}>
                            <Input
                              onChange={_fieldHandler}
                              name={`${nodeId}_timeEnd`}
                              value={timeEnd}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        )}
        <Button className={s.wrapper__submit} disabled={!video} type={"submit"}>
          Create workout
        </Button>
      </form>
    </>
  );
};